<template>
  <div class="relative">
    <!-- Mobile view (icons only) -->
    <div
      v-if="isSmallViewPort && !isExpanded"
      class="bg-white rounded-xl border !border-gray-cs-500 w-max ml-auto"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="[
          index !== items.length - 1 &&
            'border !border-t-0 !border-l-0 !border-r-0 !border-b !border-gray-cs-500',
        ]"
        class="w-10 h-10 flex items-center justify-center cursor-pointer"
        @click="toggleExpanded"
      >
        <img :src="item.icon" alt="icon" class="w-8" />
      </div>
    </div>

    <!-- Expanded view (full content) -->
    <LayoutCard
      v-else
      v-click-away="closeExpanded"
      :custom-classes="[
        isSmallViewPort && !isExpanded && 'w-0',
        isExpanded &&
          isSmallViewPort &&
          'w-full shadow-lg !absolute right-0 min-w-[20rem]',
      ]"
      body-classes="!px-4"
    >
      <template v-slot:body>
        <ul class="space-y-6">
          <li
            v-for="(item, index) in items"
            :key="index"
            class="flex items-center space-x-4 cursor-pointer"
          >
            <div
              class="w-[3.5rem] h-[3.25rem] flex items-center justify-center border !border-gray-cs-500 rounded-xl"
            >
              <img :src="item.icon" alt="icon" class="w-8" />
            </div>
            <div>
              <p class="font-semibold text-lg">{{ item.title }}</p>
              <p class="text-sm text-gray-600">{{ item.description }}</p>
            </div>
          </li>
        </ul>
      </template>
    </LayoutCard>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useWindowSize } from '@vueuse/core'
import LayoutCard from '@modules/setting/components/white-label/LayoutCard.vue'

const { width } = useWindowSize()
const items = ref([
  {
    title: 'Article',
    description: 'One liner description goes here',
    icon: require('@src/assets/img/settings/white-label/article.png'),
  },
  {
    title: 'Tutorial',
    description: 'One liner description...',
    icon: require('@src/assets/img/settings/white-label/tutorial.png'),
  },
  // {
  //   title: 'Support',
  //   description: 'Description goes here',
  //   icon: require('@src/assets/img/settings/white-label/support.png'),
  // },
  {
    title: 'Demo',
    description: 'Small description goes here',
    icon: require('@src/assets/img/settings/white-label/demo.png'),
  },
])

const isExpanded = ref(false)

const isSmallViewPort = computed(() => width.value < 1280)

const toggleExpanded = () => {
  if (isSmallViewPort.value) {
    isExpanded.value = !isExpanded.value
  }
}

const closeExpanded = () => {
  if (isSmallViewPort.value) {
    isExpanded.value = false
  }
}
</script>
