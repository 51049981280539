import { computed, ref } from 'vue'

// end points
import {
  fetchContentApprovalStatsURL,
  fetchContentPublishingStatsURL,
  fetchInboxStatsURL,
} from '@src/modules/analytics/config/api-utils'
import {
  fetchAnnouncementURL,
  fetchDashboardVideosURL,
  fetchDashboardBannerViewStatus,
} from '@src/config/api-utils'

// utils
import { useStore } from '@state/base'
import moment from 'moment'
import proxy from '@common/lib/http-common'
import { getWorkspaceTimeZoneTime } from '@common/lib/date-time'
import { EventBus } from '@common/lib/event-bus'
import { notification as notificationHelper } from '@src/modules/common/mixins/watchers/notification'
import { useRoute, useRouter } from 'vue-router'
import useDateFormat from "@common/composables/useDateFormat";
const navigationNext = `<i class="fas fa-chevron-right mb-[1px] ml-[0.125rem]"></i>`
const navigationPrev = `<i class="fas fa-chevron-left mb-[1px] mr-[0.063rem]"></i>`

export default function useDashboard() {
  const store = useStore()
  const {momentWrapper} = useDateFormat()
  const route = useRoute()
  const router = useRouter()
  const { getters, dispatch } = store

  // states
  const contentPublishingData = ref(null)
  const contentPublishingLoader = ref(false)
  const approvalPublishingDateFilterText = ref('')
  const contentPublishingDateFilterText = ref('')
  const inboxData = ref(null)
  const inboxLoader = ref(false)
  const contentApprovalData = ref(null)
  const contentApprovalLoader = ref(false)
  const newsData = ref(null)
  const newsLoader = ref(false)
  const videosData = ref(null)
  const videosLoader = ref(false)
  const dateFilters = {
    Today: 'Today',
    Yesterday: 'Yesterday',
    Tomorrow: 'Tomorrow',
    'Last 7 Days': 'Last 7 Days',
    'Last 30 Days': 'Last 30 Days',
    'Next 7 Days': 'Next 7 Days',
    'Next 30 Days': 'Next 30 Days',
    'This Month': 'This Month',
    'This Year': 'This Year',
  }

  // computed
  const hasSocialInboxAccess = computed(() => {
    if (
      Object.hasOwn(
        getters?.getActiveWorkspaceAddonsLifetime,
        'social_inbox_beta'
      ) &&
      getters?.getActiveWorkspaceAddonsLifetime?.social_inbox_beta
    ) {
      return true
    }
    if (
      Object.hasOwn(
        getters?.getActiveWorkspaceAddonsLifetime,
        'social_inbox'
      ) &&
      getters?.getActiveWorkspaceAddonsLifetime?.social_inbox
    ) {
      return true
    }
    return !!getters?.isAnalyticsPlanAllowed
  })

  const allNotifications = computed(
    () => getters.getWorkspaceNotifications.all_list.data
  )

  const allNotificationDates = computed(() => {
    const dates = []
    allNotifications.value?.forEach((item) => {
      const date = formatDateForWorkspace(item)
      if (dates.indexOf(date) === -1) dates.push(date)
    })
    return dates
  })

  const allUnReadNotificationsCount = computed(
    () => getters?.getWorkspaceNotifications?.all_count
  )

  // Check the twiiter accounts and check if developer app is connected
  const hasTwitterCustomApp = computed(() => {
    return getters.getTwitterAccounts?.items.some((account) => {
      return account?.developer_app_id
    })
  })

  // methods
  const getFilterDate = (text) => {
    switch (text) {
      case 'Today':
        return (
          moment().format('MMM DD, YYYY') +
          ' - ' +
          moment().format('MMM DD, YYYY')
        )
      case 'Yesterday':
        return (
          moment().subtract(1, 'days').format('MMM DD, YYYY') +
          ' - ' +
          moment().subtract(1, 'days').format('MMM DD, YYYY')
        )
      case 'Tomorrow':
        return (
          moment().add(1, 'days').format('MMM DD, YYYY') +
          ' - ' +
          moment().add(1, 'days').format('MMM DD, YYYY')
        )
      case 'Last 7 Days':
        return (
          moment().subtract(6, 'days').format('MMM DD, YYYY') +
          ' - ' +
          moment().format('MMMM DD, YYYY')
        )
      case 'Last 30 Days':
        return (
          moment().subtract(29, 'days').format('MMM DD, YYYY') +
          ' - ' +
          moment().format('MMMM DD, YYYY')
        )
      case 'Next 7 Days':
        return (
          moment().format('MMM DD, YYYY') +
          ' - ' +
          moment().add(6, 'days').format('MMMM DD, YYYY')
        )
      case 'Next 30 Days':
        return (
          moment().format('MMM DD, YYYY') +
          ' - ' +
          moment().add(29, 'days').format('MMMM DD, YYYY')
        )
      case 'This Week':
        return (
          moment().startOf('week').format('MMM DD, YYYY') +
          ' - ' +
          moment().endOf('week').format('MMM DD, YYYY')
        )
      case 'This Month':
        return (
          moment().startOf('month').format('MMM DD, YYYY') +
          ' - ' +
          moment().endOf('month').format('MMM DD, YYYY')
        )
      case 'Last Month':
        return (
          moment()
            .subtract(1, 'month')
            .startOf('month')
            .format('MMM DD, YYYY') +
          ' - ' +
          moment().subtract(1, 'month').endOf('month').format('MMM DD, YYYY')
        )
      case 'This Quarter':
        return (
          moment().startOf('quarter').format('MMM DD, YYYY') +
          ' - ' +
          moment().endOf('quarter').format('MMM DD, YYYY')
        )
      case 'This Year':
        return (
          moment().startOf('year').format('MMM DD, YYYY') +
          ' - ' +
          moment().endOf('year').format('MMM DD, YYYY')
        )
    }
    return null
  }

  const getPercentage = (current = 0, max = 0) => {
    return (current / max) * 100
  }

  // returns stats => {published, scheduled, partial, failed}
  const getContentPublishingStats = async (filter = '') => {
    if (filter === contentPublishingDateFilterText.value) return
    contentPublishingDateFilterText.value = filter
    contentPublishingLoader.value = true
    try {
      const response = await proxy.post(fetchContentPublishingStatsURL, {
        workspace_id: getters.getActiveWorkspace?._id,
        date_range: getFilterDate(contentPublishingDateFilterText.value),
      })

      if (response?.data?.status) {
        contentPublishingData.value = response.data.stats
      }
    } catch (e) {
    } finally {
      contentPublishingLoader.value = false
    }
  }

  // returns stats => {ASSIGNED, MARKED_AS_DONE, UNASSIGNED}
  const getInboxStats = async () => {
    inboxLoader.value = true

    try {
      const response = await proxy.post(fetchInboxStatsURL, {
        workspace_id: getters.getActiveWorkspace?._id,
      })
      if (response?.data?.status) inboxData.value = response.data.stats
    } catch (e) {
    } finally {
      inboxLoader.value = false
    }
  }

  // returns stats => {missed, rejected, review}
  const getApprovalPublishingStats = async (filter = '') => {
    if (filter === approvalPublishingDateFilterText.value) return
    approvalPublishingDateFilterText.value = filter
    contentApprovalLoader.value = true
    try {
      const response = await proxy.post(fetchContentApprovalStatsURL, {
        workspace_id: getters.getActiveWorkspace?._id,
        date_range: getFilterDate(approvalPublishingDateFilterText.value),
      })

      if (response?.data?.status) {
        contentApprovalData.value = response.data.stats
      }
    } catch (e) {
    } finally {
      contentApprovalLoader.value = false
    }
  }

  // returns announcements/news data
  const getNewsData = async () => {
    newsLoader.value = true
    try {
      const response = await proxy.get(fetchAnnouncementURL)
      if (response?.data) {
        newsData.value = response.data
      }
    } catch (e) {
    } finally {
      newsLoader.value = false
    }
  }

  // returns youtube videos data
  const getVideosData = async () => {
    videosLoader.value = true
    try {
      const response = await proxy.get(fetchDashboardVideosURL)
      if (response?.data) {
        videosData.value = response.data
      }
    } catch (e) {
    } finally {
      videosLoader.value = false
    }
  }

  const checkNotificationDateInclusion = (day, item) =>
    day === formatDateForWorkspace(item)

  const formatDateForWorkspace = (item) => {
    let date = item.created_at ? item.created_at : item.created_time
    date = getWorkspaceTimeZoneTime(
      date,
      getters.getActiveWorkspace?.timezone,
      'YYYY-MM-DD HH:mm:ss'
    )
    date = date.substring(0, 10)
    return date
  }

  const fetchAllNotifications = async ($state) => {
    if (
      !getters.getNotificationsFetchState &&
      getters?.getWorkspaceNotifications?.all_list?.current_page <=
        getters?.getWorkspaceNotifications?.all_list?.last_page
    ) {
      await dispatch('fetchWorkspaceNotifications', { $state })
    }
  }

  const onNotificationClick = async (notification) => {
    if (
      await notificationHelper?.methods?.onClickNotification(
        notification,
        store,
        route,
        router
      )
    )
      EventBus.$emit('refreshPlannerTableV2')
  }

  // const setBlackFridayBannerView = async (removePermanently = false) => {
  //   EventBus.$emit('hide-friday-banner')
  //   if(!removePermanently) return
  //   try {
  //     await proxy.post(blackFridayBannerView, {
  //       show: false,
  //     })
  //   } catch (e) {
  //     dispatch('toastNotification', {
  //       message:
  //           'Could not save preference to hide the banner.',
  //       type: 'error',
  //     })
  //   }
  // }

  const setDashboardBannerStatus = async (removePermanently = false) => {
    EventBus.$emit('hide-dashboard-banner')
    if (!removePermanently) return
    try {
      await proxy.post(fetchDashboardBannerViewStatus, {
        show: false,
      })
      await dispatch('setDashboardBannerStatus', false)
    } catch (e) {
      await dispatch('toastNotification', {
        message: 'Could not save preference to hide the banner.',
        type: 'error',
      })
    }
  }

  /**
   * Returns the color of the icon based on the notification type and title.
   * The function uses a lookup object to map notification types to their corresponding colors.
   * If the color is a function (as it is for 'post_publish' and 'inbox'), it calls the function with the notification title as an argument.
   * If the color is not found in the lookup object, it defaults to 'text-cs-primary'.
   * @param {Object} notification - The notification object.
   * @returns {string} - The color class for the icon.
   */
  const notificationIconColors = {
    post_publish: (title) => {
      if (title === 'Post Published') return 'text-[#5EBC7E]'
      if (title === 'Post Failed') return 'text-[#EB554D]'
      if (title === 'Post Approved') return 'text-[#00CC00]'
      return 'text-[#EB516B]'
    },
    inbox: (title) =>
      title.includes('archived') ? 'text-[#B0B0B0]' : 'text-[#1ecb7b]',
    automation_queued: 'text-[#FF9933]',
    reject_approval: 'text-[#EB516B]',
    pending_approval: 'text-[#5FB6F9]',
    approve_approval: 'text-[#00CC00]',
    post_rejected: 'text-[#EB516B]',
    removed_from_workspace: 'text-[#FFA500]',
    role_changed: 'text-[#FFCC99]',
    new_comment: 'text-[#e2dc64]',
    posts_rejected: 'text-[#EB516B]',
    account_expired: 'text-[#FF00FF]',
    new_post_created: 'text-[#1ecb7b]',
    new_task_assigned: 'text-[#00BFFF]',
    workspace_removed: 'text-[#FFA500]',
    posts_approved: 'text-[#00CC00]',
    post_approved: 'text-[#00CC00]',
    added_to_workspace: 'text-[#99FF99]',
    mention_in_comment: 'text-[#99CCFF]',
    opml_import: 'text-[#0073E6]',
    feedly_import: 'text-[#00FFFF]',
    task_assigned_completed: 'text-[#008000]',
    workspace_joined: 'text-[#33CC33]',
    missed_plans: 'text-[#FF9900]',
    comment_in_my_post: 'text-[#e2dc64]',
  }

  const getNotificationIconColor = (notification) => {
    if (notification.type) {
      const color = notificationIconColors[notification.type]
      return typeof color === 'function'
        ? color(notification.title)
        : color || 'text-cs-primary'
    }
  }

  /*
    @param arrayToSort: array of objects to sort
    @param dateExtractor: function to extract date from object
    @returns sorted array
  */
  const sortOnDateBasis = (
    arrayToSort = [],
    dateExtractor = (defaultValue) => defaultValue
  ) => {
    return arrayToSort.sort(
      (a, b) => new Date(dateExtractor(b)) - new Date(dateExtractor(a))
    )
  }

  const formatNotificationDate = (date = '')=> {
    if(!date) return
    date = momentWrapper(date)
    if (date.isSame(moment(), 'day')) return 'Today'
    if (date.isSame(moment().subtract(1, 'days').startOf('day'), 'day'))
      return 'Yesterday'
    return date.formatDate()
  }

  return {
    contentPublishingData,
    contentPublishingLoader,
    inboxData,
    inboxLoader,
    contentApprovalData,
    contentApprovalLoader,
    newsData,
    newsLoader,
    videosData,
    videosLoader,
    approvalPublishingDateFilterText,
    contentPublishingDateFilterText,
    hasSocialInboxAccess,
    allNotifications,
    allNotificationDates,
    allUnReadNotificationsCount,
    dateFilters,
    navigationNext,
    navigationPrev,
    hasTwitterCustomApp,

    sortOnDateBasis,
    getPercentage,
    getContentPublishingStats,
    getInboxStats,
    getApprovalPublishingStats,
    getFilterDate,
    checkNotificationDateInclusion,
    fetchAllNotifications,
    onNotificationClick,
    getNewsData,
    getVideosData,
    setDashboardBannerStatus,
    getNotificationIconColor,
    formatNotificationDate,
  }
}
